<template>
    <div>
        <div class="banner">
            <h1>高校就业</h1>
            <strong>企业招聘快速通道</strong>
            <div class="bannerbtn">
                <span @click="gotoYy">报到证预约</span>
                <!-- <span>报到证查询</span> -->
            </div>
        </div>
        <div class="jzbox flex align-center justify-between">
            <div class="jzleft">
                <strong>找放心兼职</strong>
                <p>Find rest assured part-time job</p>
                <em>兼职靠谱很重要</em>
                <div class="jzsearch">
                    <div class="jzsearchitem">
                        <span @click="goSearch('派单')">派单</span>
                        <u></u>
                        <span @click="goSearch('销售')">销售</span>
                    </div>
                    <div class="jzsearchitem">
                        <span @click="goSearch('地推')">地推</span>
                        <u></u>
                        <span @click="goSearch('家教')">家教</span>
                    </div>
                    <div class="jzsearchitem">
                        <span @click="goSearch('模特')">模特</span>
                        <u></u>
                        <span @click="goSearch('导购')">导购</span>
                    </div>
                </div>
                <div class="seemorebox flex align-center justify-center">
                    <span @click="goSearch2(2)">查看更多</span>
                </div>
            </div>
            <div class="jzright">
                <div class="jzlist flex ">
                    <div class="jzitem" v-for="(item,index) in jzlist" @click="gotoJob(item)">
                        <div class="jzitemtitle">{{item.workName}}</div>
                        <div class="company">{{item.companyName}}</div>
                        <div class="itemdata">{{item.createTime}} 发布</div>
                        <div class="jzgzbox flex align-center">
                            <span>{{item.salaryName}}</span>
                            <!-- <em>元/小时</em>
                            <i>月结</i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="jzbox flex align-center justify-between">
            <div class="jzleft">
                <strong>实习不用愁</strong>
                <p>NO WARRY ABOUT INTERSHIP</p>
                <em>第一份实习很重要</em>
                <div class="jzsearch">
                    <div class="jzsearchitem">
                        <span @click="goSearch('百度')">百度</span>
                        <u></u>
                        <span @click="goSearch('电信')">电信</span>
                    </div>
                    <div class="jzsearchitem">
                        <span @click="goSearch('管理')">管理</span>
                        <u></u>
                        <span @click="goSearch('经济')">经济</span>
                    </div>
                    <div class="jzsearchitem">
                        <span @click="goSearch('电子')">电子</span>
                        <u></u>
                        <span @click="goSearch('银行')">银行</span></span>
                    </div>
                </div>
                <div class="seemorebox flex align-center justify-center">
                    <span @click="goSearch2(4)">查看更多</span>
                </div>
            </div>
            <div class="jzright">
                <div class="jzlist flex ">
                    <div class="jzitem" v-for="(item,index) in sxlist" @click="gotoJob(item)">
                        <div class="jzitemtitle">{{item.workName}}</div>
                        <div class="company">{{item.companyName}}</div>
                        <div class="itemdata">{{item.createTime}} 发布</div>
                        <div class="jzgzbox flex align-center">
                            <span>{{item.salaryName}}</span>
                            <!-- <em>元/小时</em>
                            <i>月结</i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="gxtitle flex align-center justify-center">
            <span></span>
            <strong>高校新闻</strong>
            <span></span>
        </div>

        <div class="gxnewsbox flex align-center justify-between">
            <div class="gxboxitem" v-for="(item,index) in newslist">
                <div class="gxnewstitle flex align-center">
                    <img src="/img/newstitleicon.jpg" alt="">
                    <strong>{{item.title}}</strong>
                </div>
                <div class="gxnewslist">
                    <div class="gxnewsitem flex align-center justify-between" v-for="sonitem in item.newsList" @click="gotoShownews(sonitem)">
                        <span>· {{sonitem.title}}</span>
                        <i>{{sonitem.creationTime.substr(0,10)}}</i>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="gxtitle flex align-center justify-center">
            <span></span>
            <strong>就业创业指导</strong>
            <span></span>
        </div>

        <div class="jyzdbox flex align-center justify-between">
            <div class="jyzdboxleft"  v-if="zdlist && zdlist.length>=1">
                <div class="jyzdlefttop">
                    <video :src="zdlist[checkzd].videoUrl" width="527" height="300" controls></video>
                </div>
                <div class="jyzdleftbto flex align-center justify-center">
                    <span v-if="zdlist.length>=1" @click="checkzd=0"><img :src="zdlist[0].coverPic" alt=""></span>
                    <span v-if="zdlist.length>=2" @click="checkzd=1"><img :src="zdlist[1].coverPic" alt=""></span>
                    <span v-if="zdlist.length>=3" @click="checkzd=2"><img :src="zdlist[2].coverPic" alt=""></span>
                </div>
            </div>
            <div class="jyzdboxright">
                <div class="jyzrightitem flex align-center justify-between" v-for="item in zdlist" @click="gotoShowzd(item)">
                    <div class="flex align-center">
                        <i><img src="/img/gxnewsicon.jpg" alt=""></i>
                        <span>{{item.title}}</span>
                    </div>
                    <em>{{item.createTime}}</em>
                </div>
            </div>
        </div> -->

        <div v-loading="loading">
            <div class="school">
                <div class="schoolitem" v-for="(item, index) in list">
                    <div class="itemtop">
                        <i><img :src="item.schoolLogo" width="50" alt="" /></i>
                        <strong>{{ item.schoolName }}</strong>
                    </div>
                    <div class="itemcenter">{{ item.schoolDescribe }}</div>
                    <div class="itembot">
                        <router-link to="/newUserLogin" target="_blank"
                        >实习求职</router-link
                        >
                        <a :href="item.schoolWeb" target="_blank">
                            {{item.isHighSchool?'高校介绍':'学校介绍'}}
                        </a>
                    </div>
                    <div class="itembot" style="margin-top:6px;">
                        <span style="background:#298dff;" @click="$router.push({path:'/school/news',query:{id:item.id}})">
                        {{item.isHighSchool?'高校新闻':'学校新闻'}}</span>
                        <span style="background:#ff8026;" @click="$router.push({path:'/school/zhidao',query:{id:item.id}})">就业指导</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import userRequest from "@/api/user";
export default{
    data(){
        return{
            checkzd:0,
            jzleft:[],
            jzlist:[],
            sxlist:[],
            list: [],
            loading: false,
            zdlist:[],
            newslist:[],
            userInfo:null,
            roleId: null,
        }
    },
    created() {
        let userInfo = window.localStorage.getItem('userInfo');
        userInfo && (this.userInfo = JSON.parse(userInfo));
        let roleId = Number(window.localStorage.getItem('roleId'));
        this.roleId = roleId;
        this.getJz()
        this.getSx()
        this.getZhidao()
        this.getNews()
        this.getList();
    },
    methods: {
        gotoYy(){
            if(this.userInfo && this.roleId == 99){
                this.$router.push('/school/yuyue')
            }else{
                this.$message.warning("请先登录！")
            }
            
        },
        gotoJob(item){
            const {href} = this.$router.resolve({
                path: '/user/jobDetail',
                query: {
                    workId: item.id,
                    workName:item.workName
                }
            })
            window.open(href, '_blank')
        },
        gotoShowzd(item){
            const {href} = this.$router.resolve({
                path: '/school/showzd',
                query: {
                    id: item.id,
                }
            })
            window.open(href, '_blank')
        },
        gotoShownews(item){
            const {href} = this.$router.resolve({
                path: '/school/show',
                query: {
                    id: item.id,
                }
            })
            window.open(href, '_blank')
        },
        getZhidao(){
            userRequest.curriculumindex({pageNo:1,pageSize:8}).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.zdlist=res.data.list
                }
            })
        },
        getNews(){
            userRequest.schoolNewsindex().then(res=>{
                console.log(res)
                if(res.code==200){
                    this.newslist=res.data
                    
                }
            })
        },
        goSearch2(val){
            this.$router.push({path:'/user/searchJobs',query:{workType:val}})
        },
        goSearch(val){
            this.$router.push({path:'/user/searchJobs',query:{workName:val}})
        },
        getJz(){
            userRequest.getNewWork({type:2}).then(res=>{
                // console.log(res)
                if(res.code==200){
                    this.jzleft=res.data.industryNames
                    this.jzlist=res.data.works
                }
            })
        },
        getSx(){
            userRequest.getNewWork({type:4}).then(res=>{
                // console.log(res)
                if(res.code==200){
                    this.sxlist=res.data.works
                }
            })
        },
        getList() {
            this.loading = true;
            userRequest.getSchool({ pageNo: 1, pageSize: 100 }).then((res) => {
                this.loading = false;
                console.log(6666);
                console.log(res);
                this.list = res.data.list;
                this.total = res.data.total;
            });
        },
    },
}
</script>
<style scoped>
.banner{ height: 440px; padding-top: 40px; background: url("/img/gxbanner.jpg") no-repeat; background-size: contain;}
.banner h1{ margin:0 auto 0 auto; padding-top: 60px; font-size: 50px; color: #ffffff; text-align: center; text-shadow: rgba(0,0,0,0.2) 0 0 6px;}
.banner strong{font-size: 40px; font-weight: normal; display: block; text-align: center;color: #ffffff; text-shadow: rgba(0,0,0,0.2) 0 0 6px; margin-top: 30px;}
.jzbox{ width: 1200px; background: #ffffff; margin: 30px auto 0 auto; height: 400px;}
.jzleft{ width: 252px;}
.jzright{ flex: 1;}
.jzleft strong{ display: block; text-align: center; font-size: 20px;}
.jzleft p{ display: block; text-align: center; color: #aaaaaa;text-transform:uppercase; font-size: 12px; margin-top: 6px;}
.jzleft em{ display: block; color: #2b8eff; margin: 15px auto 0 auto; line-height: 38px; font-size: 18px; font-style: normal; width: 155px; text-align: center; height: 38px; border-top: #c4dfff solid 1px;border-bottom: #c4dfff solid 1px;}
.jzsearchitem{ display: flex; align-items: center; justify-content: center; text-align: center; height: 35px;}
.jzsearchitem span{ cursor: pointer; font-size: 12px;}
.jzsearchitem u{ display: inline-block; margin: 0 15px; height: 13px; border-left: #cecece solid 1px;}
.jzsearch{ margin-top: 15px;}
.seemorebox{ margin-top: 20px;}
.seemorebox span{ cursor: pointer; color: #787b78; border-radius: 15px; border: #f0f0f0 solid 1px; width: 94px; height: 30px; display:flex; align-items: center; justify-content: center;}
.jzlist{  width: 100%; padding-top: 30px; flex-wrap: wrap; height: 367px;}
.jzitem{ cursor: pointer; margin-right: 6px; width: 300px; padding:10px 20px; height: 160px; background: #fafafa;}
/* .jzitem:hover{border:#f0f0f0 solid 1px;} */
.jzitemtitle{ height: 38px; font-size: 18px; line-height: 38px;overflow: hidden;}
.company{ font-size: 14px; color: #a1a5ad;margin-top:10px;}
.itemdata{font-size: 14px; color: #a1a5ad; margin-top:12px;}
.jzgzbox{ margin-top: 15px;}
.jzgzbox span{ font-size: 18px; color: #ff2525;}
.jzgzbox em{ font-style: normal; color: #474747; margin: 0 6px;}
.jzgzbox i{font-style: normal; color: #7e807e;}
.bannerbtn{ display: flex; margin-top: 50px; align-items: center; justify-content: center;}
.bannerbtn span{ cursor: pointer; margin: 0 10px; border-radius: 26px; width: 158px; height: 55px; display: flex; justify-content: center; align-items: center;}
.bannerbtn span:nth-child(1){ background: #FF5959; color: #ffffff; font-size: 16px;}
.bannerbtn span:nth-child(2){ background: #FF8159; color: #ffffff; font-size: 16px;}
.gxtitle{ padding: 47px 0 27px 0;}
.gxtitle strong{ font-size: 24px; margin: 0 16px;}
.gxtitle span{ display: inline-block; width: 80px; height: 1px; border-bottom: #d0d0d0 solid 1px;}
.gxnewsbox{ width: 1200px; margin: 0 auto 0 auto;}
.gxboxitem{ width: 390px; height: 300px; background: #ffffff;}
.gxnewstitle{ height: 80px; margin: 0 25px; border-bottom: #e7e7e7 solid 1px;}
.gxnewstitle strong{ margin-left: 10px; font-size: 18px;}
.gxnewslist{ margin: 12px 25px 0 25px;}
.gxnewslist i{ text-align: right; font-style: normal; width: 81px; font-size: 12px; color: #969ca4;}
.gxnewsitem span{ cursor: pointer;}
.gxnewsitem{ height: 32px;}
.gxnewsitem span:hover{ color: #f90;}
.jyzdbox{ width: 1200px; padding: 24px; margin: 0 auto 0px auto; background: #ffffff;}
.jyzdboxleft{ width: 527px; height: 408px;}
.jyzdboxright{ height: 408px; width: 595px;}
.jyzrightitem{ border-bottom: #eeeff1 solid 1px; height: 50px;}
.jyzrightitem span{ font-size: 16px; cursor: pointer; margin-left: 5px;}
.jyzrightitem em{ text-align: center; color: #838993; width: 162px; font-style: normal;}
.jyzdlefttop{ height: 300px; }
.jyzdleftbto{ margin-top: 15px;}
.jyzdleftbto span{ border:#ffffff solid 2px; margin: 0 5px 0 5px; cursor: pointer; display: block; width: 162px; height: 70px;}
.jyzdleftbto span:hover{border:#ffcf00 solid 2px;}
.jyzdleftbto span img{ width: 100%; height: 66px;}

.school {
  width: 1200px;
  margin: 0 auto 0 auto;
  padding: 50px 0 40px 0;
  display: flex;
  flex-wrap: wrap;
}
.schoolitem {
  box-sizing: border-box;
  padding: 12px 18px;
  margin-bottom: 15px;
  margin-right: 15px;
  width: 288px;
  height: 369px;
  background: #fff;
  border-radius: 10px;
  border: #eeeeee solid 1px;
}
.schoolitem:nth-child(4n) {
  margin-right: 0;
}
.itemtop {
  display: flex;
  height: 50px;
  align-items: center;
}
.itemtop i {
  display: block;
  width: 50px;
  height: 50px;
}
.itemtop i img {
  display: block;
  border-radius: 25px;
}
.itemtop strong {
  margin-left: 15px;
  font-size: 20px;
  line-height: 120%;
}
.itemcenter {
  color: #535454;
  line-height: 150%;
  height: 176px;
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.itembot {
  text-align: center;
  margin-top: 20px;
}
.itembot a {
  display: inline-block;
  width: 45%;
  height: 42px;
  border-radius: 8px;
  line-height: 42px;
  color: #fff;
  background: #5dba8d;
  text-decoration: none;
  font-size: 16px;
}
.itembot span {
  display: inline-block;
  cursor: pointer;
  width: 45%;
  height: 42px;
  border-radius: 8px;
  line-height: 42px;
  color: #fff;
  background: #5dba8d;
  text-decoration: none;
  font-size: 16px;
}
.itembot a:first-child {
  margin-right: 5px;
  background: #4a4160;
}
.itembot span:first-child {
  margin-right: 5px;
  background: #4a4160;
}
.scrollbar {
  width: 15px;
  height: 300px;
  margin: 0 auto;
}
.itemcenter::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.itemcenter::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  /* box-shadow   : inset 0 0 5px #535353; */
  background: #e8e8e8;
}
.itemcenter::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow   : inset 0 0 5px #535353; */
  border-radius: 5px;
  background: #f9f9f9;
}
.schoolbanner {
}
.schoolbanner img {
  display: block;
  width: 100%;
}



</style>

